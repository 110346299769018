<template>
  <div v-if="event">
    <i @click="toggleShowModal()" class="fas fa-share-alt is-clickable is-unselectable"></i>
    <transition name="fade">
      <div
      class="modal share-screen-modifier"
      v-show="showModal"
      v-bind:class="{'is-active': showModal}"
      >
        <div @click="toggleShowModal()" class="modal-background pointer"></div>
        <div class="modal-content">
          <div class="card has-rounded-top-corners">
            <div class="card-content share-card-modifier">
              <div class="container has-text-weight-semibold has-text-centered">
                <h3 class="title is-4 has-text-brand-color mb-5">
                Share link
                </h3>
              </div>
              <div v-if="urlAdmin" class="container mb-5">
                <div class="is-size-5 is-unselectable has-text-brand-color mb-2">
                Can view and edit
                </div>
                <div class="is-size-7 has-text-brand-color mb-2 break-word">
                {{ urlAdmin }}
                </div>
                <div
                @click="copy(urlAdmin)"
                class="button is-info is-fullwidth"
                v-bind:class="{'is-outlined': urlAdmin !== copied}"
                >
                {{ urlAdmin === copied ? 'Copied' : 'Copy link' }}
                </div>
              </div>
              <div class="container">
                <div class="is-size-5 is-unselectable has-text-brand-color mb-2">
                Can view only
                </div>
                <div class="is-size-7 has-text-brand-color mb-2 break-word">
                {{ urlNoAdmin }}
                </div>
                <div
                @click="copy(urlNoAdmin)"
                class="button is-info is-fullwidth"
                v-bind:class="{'is-outlined': urlNoAdmin !== copied}"
                >
                {{ urlNoAdmin === copied ? 'Copied' : 'Copy link' }}
                </div>
              </div>
              <div
              @click="toggleShowModal()"
              class="container mb-6 mt-4 has-text-centered has-text-grey"
              >
                <div class="is-clickable is-unselectable back-button-modifier">
                  back
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Share',
  data() {
    return {
      showModal: false,
      copied: false
    }
  },
  methods: {
    toggleShowModal() {
      this.showModal = !this.showModal
    },
    setCopiedToFalse(){
      this.copied = false
    },
    copy(url) {
      navigator.clipboard.writeText(url)
      .then(() => {
        this.copied = url
        setTimeout(this.setCopiedToFalse, 1000)
      })
      .catch(err => {
        console.log(err)
      })
    }
  },
  computed: {
    event() {
      return this.$store.state.event
    },
    adminKey() {
      return this.$store.state.adminKey
    },
    urlNoAdmin() {
      return `https://bestbillsplitter.com/view/${this.event.nameSlug}/${this.event.eventId}`
    },
    urlAdmin() {
      if(this.adminKey){
        return `https://bestbillsplitter.com/edit/${this.event.nameSlug}/${this.event.eventId}/${this.adminKey}`
      }
      return null
    }
  }
}
</script>

<style>
.share-screen-modifier {
  height: 100vh;
  flex-direction: column-reverse !important;
  justify-content: end !important;
  z-index: 31;
}

.share-card-modifier {
  height: 70vh;
}
</style>