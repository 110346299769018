<template>
  <div id="app" class="app">
    <Navbar/>
    <transition-page>
      <router-view/>
    </transition-page>
  </div>
</template>

<script>
import Vue from 'vue'
import TransitionPage from './components/TransitionPage.vue'
import Navbar from '@/components/layout/Navbar'

export default Vue.extend({
  name: 'App',
  components: {
    Navbar,
    TransitionPage
  }
})
</script>

<style>
@import '../node_modules/bulma/css/bulma.css';

@font-face {
  font-family: Montserrat; 
  src: url('./assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.has-monserrat-font {
  font-family: 'Montserrat', sans-serif;
}

.break-word {
  overflow-wrap: anywhere;
}

.unselectable {
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.no-pointer {
  cursor: auto;
}

.crop {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
}

.has-rounded-top-corners {
  border-radius: 25px 25px 0px 0px !important;
}

.bill-row-column {
  display: flex;
  flex-direction: column;
}

.spinning {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.glow {
  animation: glowing 1000ms infinite;
}

@keyframes glowing {
  0% { box-shadow: 0 0 5px #dc3232; }
  /* 40% { box-shadow: 0 0 5px #3298dc; }
  60% { box-shadow: 0 0 5px #3298dc; } */
  100% { box-shadow: 0 0 5px #dc3232; }
}

.is-hidden {
  visibility: hidden;
}

.toast-modifier {
  color: rgb(78, 78, 78) !important;
  background-color: hsl(206, 70%, 96%) !important;
}
</style>