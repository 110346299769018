import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import('../views/Start.vue')
  },
  {
    path: '/lucky-draw',
    name: 'LuckyDraw',
    component: () => import('../views/LuckyDraw.vue')
  },
  {
    path: '/view/:nameSlug/:eventId/',
    name: 'Main',
    component: () => import('../views/Main.vue')
  },
  {
    path: '/edit/:nameSlug/:eventId/:adminKey',
    name: 'MainAdmin',
    component: () => import('../views/Main.vue')
  },
  {
    path: "*",
    component: () => import('../views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
