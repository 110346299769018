import Vue from 'vue'
import Vuex from 'vuex'
import md5 from 'md5'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: null,
    event: null,
    baseCurrencySymbol: null,
    adminKey: null,
    canEdit: false
  },
  mutations: {
    updateName: (state, payload) => {
      if(payload) {
        localStorage.BestBillSplitterName = payload
      }
      state.name = payload
    },
    updateEventStart: (state, payload) => {
      if(
        localStorage.BestBillSplitterEventId &&
        localStorage.BestBillSplitterEventId !== payload.eventId
      ){
        localStorage.removeItem('BestBillSplitterName')
        state.name = null
      }
      localStorage.BestBillSplitterEventId = payload.eventId
      state.event = payload
    },
    updateEvent: (state, payload) => {
      if(
        localStorage.BestBillSplitterEventId &&
        localStorage.BestBillSplitterEventId !== payload.eventId
      ){
        localStorage.removeItem('BestBillSplitterName')
        state.name = null
      }
      localStorage.BestBillSplitterEventId = payload.eventId
      state.event = payload
      if(
        state.event &&
        state.event.baseCurrency
      ) {
        state.baseCurrencySymbol = state.event.baseCurrency + ' '
      } else if (state.event) {
        state.baseCurrencySymbol = '$'
      }
    },
    updateAdminKey: (state, payload) => {
      state.adminKey = payload
    },
    updateCanEdit: (state) => {
      if(state.event && state.adminKey) {
        state.canEdit = state.event.adminKeyHash === md5(state.adminKey)
      }
    }
  },
  actions: {
  },
  modules: {
  }
})