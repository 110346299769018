<template>
  <nav class="navbar has-shadow is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="columns is-gapless is-centered is-mobile mt-3 px-4">
      <div class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd">
        <nav class="level is-mobile px-0">
          <div class="level-left">
            <div
            v-if="showHomeIcon"
            @click="goToHome()"
            class="level-item is-unselectable is-clickable">
              <i class="fas fa-home has-text-grey"></i>
            </div>
            <div class="level-item">
              <div
              class="is-size-5 has-text-grey has-text-weight-bold is-unselectable is-clickable"
              @click="goToHome()"
              >
              {{ title }}
              </div>
            </div>
          </div>
          <div v-if="$route.name !== 'Start'" class="level-right">
            <div class="level-item">
              <Share />
            </div>
          </div>
        </nav>
      </div>
    </div>
  </nav>
</template>

<script>
import Share from '@/components/Share'

export default {
  name: 'Navbar',
  components: {
    Share
  },
  computed: {
    showHomeIcon() {
      return this.$store.state.event && ['Main', 'MainAdmin'].includes(this.$route.name)
    },
    title() {
      if(
        this.$store.state.event &&
        ['Main', 'MainAdmin'].includes(this.$route.name)
      ) {
        const eventName = this.$store.state.event.name
        return eventName.length < 20 ? eventName : eventName.substring(0, 19) + '...'
      }
      return 'BEST BILL SPLITTER'
    }
  },
  methods: {
    goToHome() {
      if(this.$route.name !== 'Start') {
        this.$router.push({ name: 'Start'})
      } else {
        this.$router.push({ name: 'Home'})
      }
    }
  }
}
</script>

<style scoped>
.navbar {
  display: inherit !important;
}

.navbar .navbar-brand {
  text-align: center;
  display: block;
}

.navbar .navbar-brand > .navbar-item,
.navbar .navbar-brand .navbar-link {
  display: inline-block;
}

.navbar-brand-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 53px;
}
</style>