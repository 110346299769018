import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag"
import { firestorePlugin } from 'vuefire'
import Toasted from 'vue-toasted'
import VueMeta from 'vue-meta'

Vue.use(firestorePlugin)
Vue.use(VueGtag, {
  config: { id: "G-6867EZNXW1" },
  appName: 'Best Bill Splitter',
  pageTrackerScreenviewEnabled: true
}, router)
Vue.use(Toasted)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

Number.prototype.countDecimals = function () {
  if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0; 
}

const isTest = false
if(isTest){
  console.log = function() { return true }
  console.warn = function() { return true }
  console.debug = function () { return true }
  console.info = function () { return true }
  console.error = function() { return true }
  console.time = function() { return true }
  console.timeEnd = function() { return true }
}

store.commit('updateName', localStorage.BestBillSplitterName ? localStorage.BestBillSplitterName : null)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
